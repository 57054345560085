import { Container } from 'inversify';
// tslint:disable-next-line:no-import-side-effect
import 'reflect-metadata';
import { ICombatManager } from '../contracts/combat-manager';
import { INPCAbilityManager } from '../contracts/npc-ability-manager';
import { NPCAbilityManager } from '../npc-ability-manager';
import { BossSpawner } from './../boss-spawner';
import { CombatManager } from './../combat-manager';
import { CombatOutcome } from './../combat-outcome';
import { IBossSpawner } from './../contracts/boss-spawner';
import { ICombatOutcome } from './../contracts/combat-outcome';
import { ILocalStorageDatabaseManipulator } from './../contracts/data/localStorageDatabaseManipulator';
import { IPlayerStatsUpdater } from './../contracts/dom-manipulator/player-stats-updater';
import { ISetAbilityTitles } from './../contracts/dom-manipulator/set-ability-titles';
import { IEngine } from './../contracts/engine/engine';
import { IDomManipulator } from './../contracts/models/DOMManipulation/IDomManipulator';
import { IInventory } from './../contracts/models/inventory';
import { LocalStorageDatabaseManipulator } from './../data/localStorageDatabaseManipulator';
import { DOMManipulator } from './../models/DOMManipulation/domManipulator';
import { PlayerStatsUpdater } from './../models/DOMManipulation/playerStatsUpdater';
import { SetAbilityTitles } from './../models/DOMManipulation/setAbilityTitles';
import { Engine } from './../models/engine';
import { Inventory } from './../models/inventory-model';
import { TYPES } from './type';

const container: Container = new Container();

container
  .bind<IEngine>(TYPES.IEngine)
  .to(Engine)
  .inSingletonScope();

container
  .bind<IDomManipulator>(TYPES.IDomManipulator)
  .to(DOMManipulator)
  .inSingletonScope();
container
  .bind<ILocalStorageDatabaseManipulator>(
    TYPES.ILocalStorageDatabaseManipulator
  )
  .to(LocalStorageDatabaseManipulator);

container
  .bind<IPlayerStatsUpdater>(TYPES.IPlayerStatsUpdater)
  .to(PlayerStatsUpdater);

container.bind<IBossSpawner>(TYPES.IBossSpawner).to(BossSpawner);

container.bind<ICombatOutcome>(TYPES.ICombatOutcome).to(CombatOutcome);

container.bind<IInventory>(TYPES.IInventory).to(Inventory);

container.bind<ISetAbilityTitles>(TYPES.ISetAbilityTitles).to(SetAbilityTitles);
container.bind<INPCAbilityManager>(TYPES.INPCAbilityManager).to(NPCAbilityManager);

container.bind<ICombatManager>(TYPES.ICombatManager).to(CombatManager);

export { container };
