import { progressBarIDs } from './../../common/html-tag-info';
// tslint:disable-next-line:no-unnecessary-class
export class StatsBarManipulator {
  public static BOSSHEALTHUPDATER(hp: number, maxHp: number): void {
    (<HTMLInputElement>(
      document.getElementById(`${String(progressBarIDs.bossHealtBarId)}`)
    )).value = hp.toString();
    (<HTMLInputElement>(
      document.getElementById(`${String(progressBarIDs.bossHealtBarId)}`)
    )).max = maxHp.toString();
  }

  public static PLAYERHEALTHUPDATER(hp: number, maxHp: number): void {
    (<HTMLInputElement>(
      document.getElementById(`${String(progressBarIDs.playerHealtBarId)}`)
    )).value = hp.toString();
    (<HTMLInputElement>(
      document.getElementById(`${String(progressBarIDs.playerHealtBarId)}`)
    )).max = maxHp.toString();
  }

  public static PLAYERMANAUPDATER(mana: number, maxMana: number): void {
    (<HTMLInputElement>(
      document.getElementById(`${String(progressBarIDs.playerManaBarId)}`)
    )).value = mana.toString();
    (<HTMLInputElement>(
      document.getElementById(`${String(progressBarIDs.playerManaBarId)}`)
    )).max = maxMana.toString();
  }
  public static PLAYERXPUPDATER(xp: number, maxXp: number): void {
    (<HTMLInputElement>(
      document.getElementById(`${String(progressBarIDs.playerExperienceId)}`)
    )).value = xp.toString();
    (<HTMLInputElement>(
      document.getElementById(`${String(progressBarIDs.playerExperienceId)}`)
    )).max = maxXp.toString();
  }
}
