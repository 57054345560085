// tslint:disable-next-line:no-unnecessary-class
export class BossImageChanger {
  public static CHANGEIMAGE(imagePath: string): void {
    document.getElementById('bossIcon').style.backgroundImage = `url(
      '${imagePath}')`;
  }

  // tslint:disable-next-line:function-name
  public static applyTurnEffect(fighter: string): void {
    this.removeActiveTurns();
    document.getElementById(`${fighter}Icon`).classList.add('activeTurn');
  }

  private static removeActiveTurns(): void {
    document.getElementById(`bossIcon`).classList.remove('activeTurn');
    document.getElementById(`playerIcon`).classList.remove('activeTurn');
  }
}
