import { IElementsStats, IItem } from '../../contracts/models';
import { IAbility } from '../../contracts/models/abilities';
import { INPC } from '../../contracts/models/characters';
import { itemDatabase } from '../../data/item-database';
import { Character } from './character-model';

export class NPC extends Character implements INPC {
  public _imagePath: string;
  public constructor(
    name: string,
    level: number,
    hp: number,
    elementsStats: IElementsStats,
    abilities: IAbility[],
    imageName: string
  ) {
    super(name, level, hp, elementsStats, abilities);
    this._imagePath = `./src/styles/img/art/${imageName}`;
  }

  public giveItem(): IItem {
    // tslint:disable-next-line:insecure-random
    const itemToGive: number = Math.floor(Math.random() * Math.floor(12));

    return itemDatabase[itemToGive];
  }
}
