import { injectable } from 'inversify';
// tslint:disable-next-line:no-import-side-effect
import 'reflect-metadata';
import { IElementsStats, IInventory, IItem } from '../../contracts/models';
import { IAbility } from '../../contracts/models/abilities';
import { IPlayer } from '../../contracts/models/characters';
import { container } from './../../config/ioc.config';
import { TYPES } from './../../config/type';
import { Character } from './character-model';
@injectable()
export class Player extends Character implements IPlayer {
  private _experience: number;
  private _mana: number;
  private _maxMana: number;
  private _inventory: IInventory;

  public constructor(
    name: string,
    level: number,
    maxHP: number,
    elementsStats: IElementsStats,
    abilities: IAbility[],
    maxMana: number,
    experience: number
  ) {
    super(name, level, maxHP, elementsStats, abilities);
    this.experience = experience;
    this.maxMana = maxMana;
    this.mana = maxMana;
    this._inventory = container.get(TYPES.IInventory);
  }

  public get experience(): number {
    return this._experience;
  }
  public set experience(experience: number) {
    this._experience = experience;
  }

  public get mana(): number {
    return this._mana;
  }
  public set mana(manaValue: number) {
    if (manaValue > this.maxMana) {
      manaValue = this.maxMana;
    }

    this._mana = manaValue;
  }

  public get maxMana(): number {
    return this._maxMana;
  }
  public set maxMana(maxMana: number) {
    this._maxMana = maxMana;
  }

  public get inventory(): IInventory {
    return this._inventory;
  }

  public levelUP(): void {
    this.level += 1;

    this.maxHP += 30;
    this.maxMana += 20;
  }

  public removeItemStats(item: IItem): void {
    this.maxHP -= item.bonusHp;
    this.maxMana -= item.bonusMana;
    this.elementsStats.frostPoints -= item.elementsStats.frostPoints;
    this.elementsStats.firePoints -= item.elementsStats.firePoints;
    this.elementsStats.naturePoints -= item.elementsStats.naturePoints;
  }
  public addItemStats(item: IItem): void {
    this.maxHP += item.bonusHp;
    this.maxMana += item.bonusMana;
    this.elementsStats.frostPoints += item.elementsStats.frostPoints;
    this.elementsStats.firePoints += item.elementsStats.firePoints;
    this.elementsStats.naturePoints += item.elementsStats.naturePoints;
  }
}
