import { injectable } from 'inversify';
// tslint:disable-next-line:no-import-side-effect
import 'reflect-metadata';
import { AbilityHandler } from './ability-handler';
import { ICharacter } from './contracts/models';
import { IAbility } from './contracts/models/abilities/ability';
import { INPCAbilityManager } from './contracts/npc-ability-manager';

@injectable()
export class NPCAbilityManager implements INPCAbilityManager {

  public performRandomAbility(npc: ICharacter, opponent: ICharacter): IAbility {
    const randomIndex: number = Math.floor(
      // tslint:disable-next-line:insecure-random
      Math.random() * npc.abilities.length
    );
    AbilityHandler.HANDLEABILITY(npc.abilities[randomIndex], npc, opponent);

    return npc.abilities[randomIndex];
  }
}
