import { IElementsStats } from '../contracts/models';

export class ElementsStats implements IElementsStats {
  private _frostPoints: number;
  private _firePoints: number;
  private _naturePoints: number;

  public constructor(frost: number, fire: number, nature: number) {
    this.frostPoints = frost;
    this.firePoints = fire;
    this.naturePoints = nature;
  }

  public get frostPoints(): number {
    return this._frostPoints;
  }

  public set frostPoints(frost: number) {
    if (frost < 0) {
      throw new Error('Stat points cannot be less than zero!');
    }
    this._frostPoints = frost;
  }

  public get firePoints(): number {
    return this._firePoints;
  }

  public set firePoints(fire: number) {
    if (fire < 0) {
      throw new Error('Stat points cannot be less than zero!');
    }
    this._firePoints = fire;
  }

  public get naturePoints(): number {
    return this._naturePoints;
  }

  public set naturePoints(nature: number) {
    if (nature < 0) {
      throw new Error('Stat points cannot be less than zero!');
    }
    this._naturePoints = nature;
  }
}
