import { ItemType } from '../../common';
import { IElementsStats, IItem } from '../../contracts/models';

export class Item implements IItem {
  private _title: string;
  private _itemType: ItemType;
  private _elementsStats: IElementsStats;
  private _level: number;
  private _bonusHp: number;
  private _bonusMana: number;
  private _itemDesciption: string;

  public constructor(
    title: string,
    itemType: ItemType,
    stats: IElementsStats,
    level: number = 0,
    bonusHp: number,
    bonusMana: number,
    itemDesciption: string
  ) {
    this.title = title;
    this.itemType = itemType;
    this.elementsStats = stats;
    this.level = level;
    this.bonusHp = bonusHp;
    this.bonusMana = bonusMana;
    this.itemDesciption = itemDesciption;
  }

  public get title(): string {
    return this._title;
  }

  public set title(title: string) {
    this._title = title;
  }

  public get itemType(): ItemType {
    return this._itemType;
  }

  public set itemType(itemType: ItemType) {
    this._itemType = itemType;
  }

  public get elementsStats(): IElementsStats {
    return this._elementsStats;
  }

  public set elementsStats(stats: IElementsStats) {
    this._elementsStats = stats;
  }

  public get level(): number {
    return this._level;
  }

  public set level(level: number) {
    this._level = level;
  }
  public get bonusHp(): number {
    return this._bonusHp;
  }
  public set bonusHp(bonusHp: number) {
    this._bonusHp = bonusHp;
  }
  public get bonusMana(): number {
    return this._bonusMana;
  }
  public set bonusMana(bonusMana: number) {
    this._bonusMana = bonusMana;
  }
  public get itemDesciption(): string {
    return this._itemDesciption;
  }
  public set itemDesciption(itemDescription: string) {
    this._itemDesciption = itemDescription;
  }

  public upgrade(): void {
    this.level += 1;

    if (this.level > 1) {
      this.elementsStats.firePoints *= 1.5;
      this.elementsStats.frostPoints *= 1.5;
      this.elementsStats.naturePoints *= 1.5;
      this.bonusHp *= 1.5;
      this.bonusMana *= 1.5;
    }
  }
}
