import { inject, injectable } from 'inversify';
// tslint:disable-next-line:no-import-side-effect
import 'reflect-metadata';
import { BossSpawner } from './boss-spawner';
import { ItemType } from './common/item-types';
import { TYPES } from './config/type';
import { ICombatOutcome } from './contracts/combat-outcome';
import { ILocalStorageDatabaseManipulator } from './contracts/data/localStorageDatabaseManipulator';
import { IPlayerStatsUpdater } from './contracts/dom-manipulator/player-stats-updater';
import { INPC, IPlayer } from './contracts/models';
import { IDomManipulator } from './contracts/models/DOMManipulation/IDomManipulator';
import { IItem } from './contracts/models/items/item';
@injectable()
export class CombatOutcome implements ICombatOutcome {
  private _dom: IDomManipulator;
  private _db: ILocalStorageDatabaseManipulator;
  private _playerStatsUpdator: IPlayerStatsUpdater;
  public constructor(
    @inject(TYPES.IDomManipulator) dom: IDomManipulator,
    @inject(TYPES.ILocalStorageDatabaseManipulator)
    db: ILocalStorageDatabaseManipulator,
    @inject(TYPES.IPlayerStatsUpdater) playerStatsUpdater: IPlayerStatsUpdater
  ) {
    this._dom = dom;
    this._db = db;
    this._playerStatsUpdator = playerStatsUpdater;
  }

  public onWin(player: IPlayer, cpu: INPC): void {
    this._dom.logToConsole(`You've just killed ${cpu.name}`, 'redText');
    this._dom.playSound('VictorySound');
    BossSpawner.next();
    this.getOrUpdateItem(player, cpu);
    if (BossSpawner.currentBossId > 1 && BossSpawner.currentBossId % 2 === 0) {
      player.levelUP();
    }

    this._playerStatsUpdator.playerStatsUpdater(player);
    this._db.savePlayerData(player);
    this._db.savePlayerInventory(player.inventory.items);
    this._db.savePlayersPassedLevels(BossSpawner.currentBossId + 1);

    this._dom.showStartGameButton();
    this._dom.hideLoadButton();
  }

  public onDefeat(player: IPlayer, cpu: INPC): void {
    this._dom.logToConsole(`You've just got killed by ${cpu.name}`, 'redText');
    this._dom.showStartGameButton();
    this._dom.playSound('DeadSound');
    this._dom.hideLoadButton();
  }

  public getOrUpdateItem(player: IPlayer, cpu: INPC): void {
    const newItem: IItem = cpu.giveItem();
    const indexInInventory: number = player.inventory.items.indexOf(newItem);

    if (indexInInventory === -1) {
      player.inventory.addItem(newItem);
      this._dom.logToConsole(
        `This boss gives you new ${
          ItemType[newItem.itemType]
        } which will be added to your inventory and increase your stats`,
        'textItem'
      );
    } else {
      player.removeItemStats(newItem);

      this._dom.logToConsole(
        `Boss' soul upgraded your ${
          ItemType[newItem.itemType]
        }. This upgrade increases the bonus stats of your ${
          ItemType[newItem.itemType]
        }.`,
        'textItem'
      );
    }

    newItem.upgrade();
    player.addItemStats(newItem);

    this._dom.prepareItemIcons(player.inventory.items);

    console.log(player.inventory.items);
  }
}
