import { inject, injectable } from 'inversify';
// tslint:disable-next-line:no-import-side-effect
import 'reflect-metadata';
import { BossSpawner } from '../boss-spawner';
import { ILocalStorageDatabaseManipulator } from '../contracts/data/localStorageDatabaseManipulator';
import { LocalStorageDatabaseManipulator } from '../data/localStorageDatabaseManipulator';
import { AbilityType } from './../common/ability-types';
import { TYPES } from './../config/type';
import { ICombatManager } from './../contracts/combat-manager';
import { IPlayerStatsUpdater } from './../contracts/dom-manipulator/player-stats-updater';
import { IEngine } from './../contracts/engine/engine';
import { IAbility } from './../contracts/models/abilities/ability';
import { IPlayer } from './../contracts/models/characters/player';
import { IDomManipulator } from './../contracts/models/DOMManipulation/IDomManipulator';
import { Ability } from './abilities/ability-model';
import { Player } from './characters/player-model';
import { ElementsStats } from './elements-stats-model';
@injectable()
export class Engine implements IEngine {
  private _player: IPlayer;
  private _dom: IDomManipulator;
  private _playerStatsUpdater: IPlayerStatsUpdater;
  private _combatMng: ICombatManager;
  private _db: ILocalStorageDatabaseManipulator;

  constructor(
    @inject(TYPES.IDomManipulator) dom: IDomManipulator,
    @inject(TYPES.IPlayerStatsUpdater) playerStatsUpdater: IPlayerStatsUpdater,
    @inject(TYPES.ICombatManager) combatMng: ICombatManager,
    @inject(TYPES.ILocalStorageDatabaseManipulator) db: ILocalStorageDatabaseManipulator
  ) {
    this._dom = dom;
    this._playerStatsUpdater = playerStatsUpdater;
    this._combatMng = combatMng;
    this._db = db;
    this.initializePlayer();
  }

  public get player(): IPlayer {
    return this._player;
  }
  public start(): void {
    this._dom.setAbilityTitles(this.player);
    this._playerStatsUpdater.playerStatsUpdater(this.player);
    this.gameStarter(this);
    this.loadListener(this);
  }

  private initializePlayer(): void {
    if (this._player) {
      return;
    }

    this._player = new Player(
      'Hero',
      1,
      100,
      new ElementsStats(0, 0, 0),
      this.generateStartAbilities(),
      100,
      200
    );
  }

  private loadListener(self: any): void {
    document.getElementById('loadData').addEventListener('click', () => self.load(self));
  }

  // tslint:disable-next-line:typedef
  // tslint:disable-next-line:no-any
  private gameStarter(self: any): void {
    document.getElementById('startGame').addEventListener('click', () => {
      self._combatMng.init(self._player);
      self._combatMng.startFight();
    });
  }

  // tslint:disable-next-line:no-any
  private load(self: any): boolean {
    const dbb: ILocalStorageDatabaseManipulator = new LocalStorageDatabaseManipulator();

    const player: IPlayer | null = dbb.getSavedPlayerData();

    if (player) {
      self._player = player;
      BossSpawner.currentBossId = dbb.getPlayersPassedLevels();
      this._playerStatsUpdater.playerStatsUpdater(self._player);

      return true;
    }

    return false;
  }

  private generateStartAbilities(): IAbility[] {
    const abilityArr: IAbility[] = [];
    abilityArr.push(new Ability(AbilityType.Attack, 0, 20));
    abilityArr.push(new Ability(AbilityType.Fire, 30, 30));
    abilityArr.push(new Ability(AbilityType.Frost, 30, 30));
    abilityArr.push(new Ability(AbilityType.Nature, 30, 30));
    abilityArr.push(new Ability(AbilityType.Healing, 20, 40));
    abilityArr.push(new Ability(AbilityType.Mana, 0, 40));

    return abilityArr;
  }
}
