import { IAbility, ICharacter } from '../contracts/models';
import { AbilityType } from './ability-types';

// tslint:disable-next-line:no-unnecessary-class
export class ConstantCombatMessages {
  // tslint:disable-next-line:function-name
  public static getMsg(ability: IAbility, activator: ICharacter, points: number, opponent?: ICharacter): string {
    if (ability.abilityType === AbilityType.Mana) {
      return `${activator.name} restores ${points} mana.`;
    } else if (ability.abilityType === AbilityType.Healing) {
      return `${activator.name} restores ${points} health.`;
    } else {
      return `${activator.name} deals ${points} damage to ${opponent.name}.`;
    }
  }
}
