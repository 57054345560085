import { inject, injectable } from 'inversify';
// tslint:disable-next-line:no-import-side-effect
import 'reflect-metadata';
import { BossSpawner } from './boss-spawner';
import { TYPES } from './config/type';
import { IBossSpawner } from './contracts/boss-spawner';
import { ICombatManager } from './contracts/combat-manager';
import { ICombatOutcome } from './contracts/combat-outcome';
import { ICharacter, INPC, IPlayer } from './contracts/models';
import { IDomManipulator } from './contracts/models/DOMManipulation/IDomManipulator';
import { INPCAbilityManager } from './contracts/npc-ability-manager';
import { npcDatabase } from './data/npc-database';
import { AbilityIconsActivator } from './models/DOMManipulation/abilityIconsActivator';
@injectable()
export class CombatManager implements ICombatManager {
  public _player: IPlayer;
  public _cpu: INPC;
  private HALL_OF_FAME: ICharacter[] = [];
  private _turnDuration: number = 2;
  private dom: IDomManipulator;
  private combatOutcome: ICombatOutcome;
  private npcManager: INPCAbilityManager;
  private bspawn: IBossSpawner;

  public constructor(
    @inject(TYPES.ICombatOutcome) combatOutcome: ICombatOutcome,
    @inject(TYPES.IBossSpawner) bspawn: IBossSpawner,
    @inject(TYPES.IDomManipulator) dom: IDomManipulator,
    @inject(TYPES.INPCAbilityManager) npcManager: INPCAbilityManager
  ) {
    this.dom = dom;
    this.combatOutcome = combatOutcome;
    this.bspawn = bspawn;
    this.npcManager = npcManager;
  }

  // Public displayHallOfFame(): void {
  //   This.dom.logToConsole('HALL OF FAME', 'redText');
  //   This.HALL_OF_FAME.forEach((fighter: ICharacter) => {
  //     This.dom.logToConsole(`${fighter.name}`, 'redText');
  //   });
  // }

  public playerTurn(): Promise<number> {
    this.dom.applyTurnEffect('player');
    this.dom.prepareAbilityButtons(this._player.abilities, 'player');
    this.dom.checkForManaSufficiency(<IPlayer>this._player);

    // tslint:disable-next-line:typedef
    return new Promise<number>((resolve) => {
      setTimeout(() => {
        this.dom.updateAllBars(this._player, this._cpu);
        resolve(this._cpu.hp);
      }, this._turnDuration * 1000);
    });
  }

  public cpuTurn(): Promise<number> {
    this.dom.applyTurnEffect('boss');
    AbilityIconsActivator.deactivator();

    // tslint:disable-next-line:typedef
    return new Promise<number>((resolve) => {
      setTimeout(() => {
        this.npcManager.performRandomAbility(this._cpu, this._player);
        this.dom.updateAllBars(this._player, this._cpu);
        resolve(this._player.hp);
      }, this._turnDuration * 1000);
    });
  }

  public init(f1: IPlayer): void {
    this._player = f1;
    this._cpu = this.bspawn.getBoss();
    this.addToHallOfFame(this._player);
    this.addToHallOfFame(this._cpu);

    this._player.hp = this._player.maxHP;
    this._player.mana = this._player.maxMana;

    this.dom.updateAllBars(this._player, this._cpu);
    this.dom.prepareAbilityButtons(this._player.abilities, 'player');
    this.dom.changeBossImage(npcDatabase[BossSpawner.currentBossId]._imagePath);
    this.dom.prepareAbilityButtons(this._cpu.abilities, 'boss');
    this.dom.addAbilityListeners(
      this._player.abilities,
      <IPlayer>this._player,
      this._cpu
    );
    console.log((<IPlayer>this._player).inventory.items);
    this.dom.prepareItemIcons((<IPlayer>this._player).inventory.items);
    this.dom.hideStartGameButton();
    // tslint:disable-next-line:comment-format
    // this.displayHallOfFame();
  }

  public async startFight(): Promise<void> {
    const playerHP: number = await this.playerTurn();
    if (this._cpu.hp <= 0) {
      this.combatOutcome.onWin(this._player, this._cpu);

      return;
    }

    const cpuHP: number = await this.cpuTurn();
    if (this._player.hp <= 0) {
      this.combatOutcome.onDefeat(this._player, this._cpu);

      return;
    }

    this.startFight();
  }
  private addToHallOfFame(fighter: ICharacter): void {
    if (!this.HALL_OF_FAME.find((char: ICharacter) => {
      if (char.name === fighter.name) {
        return true;
      }
    })) {
      this.HALL_OF_FAME.push(fighter);
    }
  }
}
