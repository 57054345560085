import { ItemType } from './../common/item-types';
import { IItem } from './../contracts/models/items/item';
import { ElementsStats } from './../models/elements-stats-model';
import { Item } from './../models/items/item-model';

export const itemDatabase: IItem[] = [
  new Item(
    'Head',
    ItemType.Head,
    new ElementsStats(10, 0, 10),
    0,
    20,
    0,
    `Magic Head`
  ),
  new Item(
    'Shouders',
    ItemType.Shouders,
    new ElementsStats(0, 10, 0),
    0,
    0,
    20,
    `Magic Shouders`
  ),
  new Item(
    'Pants',
    ItemType.Pants,
    new ElementsStats(0, 0, 10),
    0,
    10,
    10,
    `Magic Pants`
  ),
  new Item(
    'Nail',
    ItemType.Nail,
    new ElementsStats(10, 10, 0),
    0,
    20,
    0,
    `Magic Nail`
  ),
  new Item(
    'Mask',
    ItemType.Mask,
    new ElementsStats(10, 0, 10),
    0,
    0,
    20,
    `Magic Mask`
  ),
  new Item(
    'Horn',
    ItemType.Horn,
    new ElementsStats(0, 10, 0),
    0,
    10,
    10,
    `Magic Horn`
  ),
  new Item(
    'Gloves',
    ItemType.Gloves,
    new ElementsStats(0, 0, 10),
    0,
    20,
    0,
    `Magic Gloves`
  ),
  new Item(
    'Chest',
    ItemType.Chest,
    new ElementsStats(10, 10, 0),
    0,
    0,
    20,
    `Magic Chest`
  ),
  new Item(
    'Bracelets',
    ItemType.Bracelets,
    new ElementsStats(10, 0, 10),
    0,
    10,
    10,
    'Magic Bracelets'
  ),
  new Item(
    'Boots',
    ItemType.Boots,
    new ElementsStats(0, 10, 0),
    0,
    20,
    0,
    `Magic Boots`
  ),
  new Item(
    'Belt',
    ItemType.Belt,
    new ElementsStats(0, 0, 10),
    0,
    0,
    20,
    `Magic Belt`
  ),
  new Item(
    'Cube',
    ItemType.Cube,
    new ElementsStats(10, 10, 0),
    0,
    10,
    10,
    `Magic Cube`
  )
];
