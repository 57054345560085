import { injectable } from 'inversify';
// tslint:disable-next-line:no-import-side-effect
import 'reflect-metadata';
import { AbilityType } from './../../common/ability-types';
import { ISetAbilityTitles } from './../../contracts/dom-manipulator/set-ability-titles';
import { IAbility } from './../../contracts/models/abilities/ability';
import { IPlayer } from './../../contracts/models/characters/player';
@injectable()
export class SetAbilityTitles implements ISetAbilityTitles {
  public setAbilityTitles(player: IPlayer): void {
    player.abilities.forEach((ability: IAbility) => {
      const id: string = `${AbilityType[
        ability.abilityType
      ].toLowerCase()}Ability`;

      document
        .getElementById(id)
        .setAttribute(
          'title',
          `${AbilityType[ability.abilityType]} \t ${ability.manaCost} mana\nDeals ${
            ability.effectPoints
          } base damage`
        );
    });
  }
}
