export enum ItemType {
  Head,
  Shouders,
  Pants,
  Nail,
  Mask,
  Horn,
  Gloves,
  Chest,
  Bracelets,
  Boots,
  Belt,
  Cube
}
