import { AbilityType } from './../common/ability-types';
import { INPC } from './../contracts/models/characters/npc';
import { Ability } from './../models/abilities/ability-model';
import { NPC } from './../models/characters/npc-model';
import { ElementsStats } from './../models/elements-stats-model';

export const npcDatabase: INPC[] = [
  new NPC(
    'Satyr Boss',
    1,
    100,
    new ElementsStats(0, 0, 0),
    [new Ability(AbilityType.Attack, 0, 20)],
    'Boss1.jpg'
  ),
  new NPC(
    'Troll Boss',
    2,
    140,
    new ElementsStats(0, 0, 10),
    [
      new Ability(AbilityType.Attack, 0, 30),
      new Ability(AbilityType.Healing, 0, 10)
    ],
    'Boss2.jpg'
  ),
  new NPC(
    'Bird Boss',
    3,
    180,
    new ElementsStats(0, 0, 10),
    [
      new Ability(AbilityType.Attack, 0, 20),
      new Ability(AbilityType.Nature, 0, 30)
    ],
    'Boss3.jpg'
  ),
  new NPC(
    'Rat Boss',
    4,
    200,
    new ElementsStats(0, 10, 10),
    [
      new Ability(AbilityType.Attack, 0, 30),
      new Ability(AbilityType.Healing, 0, 10)
    ],
    'Boss4.jpg'
  ),
  new NPC(
    'Dragon Boss',
    5,
    240,
    new ElementsStats(0, 20, 10),
    [
      new Ability(AbilityType.Attack, 0, 30),
      new Ability(AbilityType.Nature, 0, 30)
    ],
    'Boss5.jpg'
  )
  ,
  new NPC(
    'Bark Boss',
    5,
    280,
    new ElementsStats(10, 0, 0),
    [
      new Ability(AbilityType.Attack, 0, 20),
      new Ability(AbilityType.Healing, 0, 10),
      new Ability(AbilityType.Nature, 0, 40)
    ],
    'Boss6.jpg'
  )
  ,
  new NPC(
    'Shaman Boss',
    5,
    300,
    new ElementsStats(0, 30, 10),
    [
      new Ability(AbilityType.Attack, 0, 20),
      new Ability(AbilityType.Fire, 0, 40),
      new Ability(AbilityType.Frost, 0, 40),
      new Ability(AbilityType.Healing, 0, 10)
    ],
    'Boss7.jpg'
  )
  ,
  new NPC(
    'Bear Boss',
    5,
    340,
    new ElementsStats(30, 0, 0),
    [
      new Ability(AbilityType.Attack, 0, 70),
      new Ability(AbilityType.Nature, 0, 60),
      new Ability(AbilityType.Healing, 0, 10)
    ],
    'Boss8.jpg'
  )
  ,
  new NPC(
    'Angel Boss',
    5,
    380,
    new ElementsStats(0, 30, 30),
    [
      new Ability(AbilityType.Attack, 0, 60),
      new Ability(AbilityType.Fire, 0, 70),
      new Ability(AbilityType.Healing, 0, 15)
    ],
    'Boss9.jpg'
  )
  ,
  new NPC(
    'Creature Boss',
    5,
    400,
    new ElementsStats(0, 40, 40),
    [
      new Ability(AbilityType.Attack, 0, 70),
      new Ability(AbilityType.Fire, 0, 80),
      new Ability(AbilityType.Nature, 0, 70)
    ],
    'Boss10.jpg'
  )
  ,
  new NPC(
    'Butcher Boss',
    5,
    440,
    new ElementsStats(40, 10, 20),
    [
      new Ability(AbilityType.Attack, 0, 90),
      new Ability(AbilityType.Fire, 0, 60),
      new Ability(AbilityType.Frost, 0, 70),
      new Ability(AbilityType.Nature, 0, 70),
      new Ability(AbilityType.Healing, 0, 15)
    ],
    'Boss11.jpg'
  )
  ,
  new NPC(
    'Demon Boss',
    5,
    480,
    new ElementsStats(40, 40, 40),
    [
      new Ability(AbilityType.Attack, 0, 90),
      new Ability(AbilityType.Fire, 0, 100),
      new Ability(AbilityType.Frost, 0, 90),
      new Ability(AbilityType.Nature, 0, 80),
      new Ability(AbilityType.Healing, 0, 15)
    ],
    'Boss12.jpg'
  )
];
