import { IPlayer } from '../../contracts/models';
import { AbilityType } from './../../common/ability-types';
import { IAbility } from './../../contracts/models/abilities/ability';
// tslint:disable-next-line:no-unnecessary-class
export class AbilityIconsActivator {
  public static ABILITYICONSACTIVATOR(
    abilities: IAbility[],
    character: string
  ): void {
    if (character === 'boss') {
      const elementToEmpty: HTMLElement = document.getElementById('bossAbilities');

      while (elementToEmpty.firstChild) {
        elementToEmpty.removeChild(elementToEmpty.firstChild);
      }
    }

    abilities.forEach((ability: IAbility) => {
      if (
        Object.values(AbilityType).includes(ability.abilityType) &&
        character === 'player'
      ) {
        document
          .getElementById(
            `${AbilityType[ability.abilityType].toLowerCase()}Ability`
          )
          .classList.remove('inactive');
      } else if (
        Object.values(AbilityType).includes(ability.abilityType) &&
        character === 'boss'
      ) {
        const el: HTMLElement = document.getElementById('bossAbilities');

        const child: HTMLElement = el.appendChild(
          document
            .createElement('div')
        );

        child.setAttribute('id', `boss${AbilityType[ability.abilityType].toLowerCase()}Ability`);
        child.classList.add('bossAbilityIcon');
      }
    });
  }

  // tslint:disable-next-line:function-name
  public static deactivator(): void {
    Array
      .from(document.getElementsByClassName('abilityIcon'))
      // tslint:disable-next-line:typedef
      .forEach((ab) => ab.classList.add('inactive'));
  }

  // tslint:disable-next-line:function-name
  public static checkForManaSufficiency(player: IPlayer): void {
    player.abilities.forEach((ability: IAbility) => {
      const element: HTMLElement = document
        .getElementById(
          `${AbilityType[ability.abilityType].toLowerCase()}Ability`
        );

      if (player.mana < ability.manaCost) {
        element.classList.add('notEnoughMana');
      } else {
        element.classList.remove('notEnoughMana');
      }
    });
  }
}
