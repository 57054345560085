import { injectable } from 'inversify';
// tslint:disable-next-line:no-import-side-effect
import 'reflect-metadata';
import { ICharacter, INPC, IPlayer } from '../../contracts/models';
import { container } from './../../config/ioc.config';
import { TYPES } from './../../config/type';
import { ISetAbilityTitles } from './../../contracts/dom-manipulator/set-ability-titles';
import { IAbility } from './../../contracts/models/abilities/ability';
import { IDomManipulator } from './../../contracts/models/DOMManipulation/IDomManipulator';
import { IItem } from './../../contracts/models/items/item';
import { AbilityIconsActivator } from './abilityIconsActivator';
import { AbilityListener } from './abilityListener';
import { BossImageChanger } from './bossImageChanger';
import { ConsoleLogger } from './consoleLogger';
import { ItemIconsActivator } from './itemIconsActivator';
import { StatsBarManipulator } from './statsBarManipulator';

@injectable()
export class DOMManipulator implements IDomManipulator {
  public playSound(soundID: string): void {
    const sound: HTMLAudioElement = <HTMLAudioElement>document.getElementById(`${soundID}`);
    sound.play();
  }
  public updateBossHealthBar(npc: ICharacter): void {
    StatsBarManipulator.BOSSHEALTHUPDATER(npc.hp, npc.maxHP);
  }
  public updatePlayerHealthBar(player: ICharacter): void {
    StatsBarManipulator.PLAYERHEALTHUPDATER(player.hp, player.maxHP);
  }
  public updatePlayerManaBar(player: ICharacter): void {
    StatsBarManipulator.PLAYERMANAUPDATER(
      (<IPlayer>player).mana,
      (<IPlayer>player).maxMana
    );
  }

  public updateAllBars(player: ICharacter, npc: ICharacter): void {
    this.updateBossHealthBar(npc);
    this.updatePlayerHealthBar(player);
    this.updatePlayerManaBar(player);
  }

  public changeBossImage(imagePath: string): void {
    BossImageChanger.CHANGEIMAGE(imagePath);
  }

  public logToConsole(textToPrint: string, idToAdd: string): void {
    ConsoleLogger.APPEND(textToPrint, idToAdd);
  }

  public prepareAbilityButtons(
    abilities: IAbility[],
    characacter: string
  ): void {
    AbilityIconsActivator.ABILITYICONSACTIVATOR(abilities, characacter);
  }

  public addAbilityListeners(
    abilities: IAbility[],
    player: IPlayer,
    opponent: ICharacter
  ): void {
    AbilityListener.ADDEVENTSTOABILITIES(abilities, player, opponent);
  }

  public checkForManaSufficiency(player: IPlayer): void {
    AbilityIconsActivator.checkForManaSufficiency(player);
  }

  public prepareItemIcons(itemDatabase: IItem[]): void {
    ItemIconsActivator.ITEMICONSACTIVATOR(itemDatabase);
  }

  public setAbilityTitles(player: IPlayer): void {
    const abilityTitlesSetter: ISetAbilityTitles = container.get(
      TYPES.ISetAbilityTitles
    );
    abilityTitlesSetter.setAbilityTitles(player);
  }

  public hideStartGameButton(): void {
    document.getElementById('startGame').hidden = true;
    document.getElementById('loadData').hidden = true;
  }

  public showStartGameButton(): void {
    document.getElementById('startGame').hidden = false;
    document.getElementById('loadData').hidden = false;
  }

  public applyTurnEffect(fighter: string): void {
    BossImageChanger.applyTurnEffect(fighter);
  }

  public hideLoadButton(): void {
    document.getElementById('loadData').hidden = true;
  }

  public showLoadButton(): void {
    document.getElementById('loadData').hidden = false;
  }
}
