// tslint:disable-next-line:no-unnecessary-class
export class ConsoleLogger {
  public static APPEND(textToPrint: string, idToAdd: string): void {
    document
      .getElementById('textarea')
      .insertAdjacentHTML(
        'afterbegin',
        `<div id="${idToAdd}">${textToPrint}</div>`
      );
  }
}
