import { injectable } from 'inversify';
// tslint:disable-next-line:no-import-side-effect
import 'reflect-metadata';
import { IBossSpawner } from './contracts/boss-spawner';
import { INPC } from './contracts/models';
import { npcDatabase } from './data/npc-database';
import { NPC } from './models';
import { ElementsStats } from './models/elements-stats-model';
@injectable()
export class BossSpawner implements IBossSpawner {
  public static currentBossId: number = 0;
  // tslint:disable-next-line:function-name
  public static next(): number {
    if (BossSpawner.currentBossId + 1 === npcDatabase.length) {
      console.log('This was the last boss!');

      return BossSpawner.currentBossId;
    }
    BossSpawner.currentBossId += 1;

    return BossSpawner.currentBossId;
  }

  public getBoss(): INPC {
    return new NPC(
      npcDatabase[BossSpawner.currentBossId].name,
      npcDatabase[BossSpawner.currentBossId].level,
      npcDatabase[BossSpawner.currentBossId].hp,
      new ElementsStats(
        npcDatabase[BossSpawner.currentBossId].elementsStats.frostPoints,
        npcDatabase[BossSpawner.currentBossId].elementsStats.firePoints,
        npcDatabase[BossSpawner.currentBossId].elementsStats.naturePoints
      ),
      npcDatabase[BossSpawner.currentBossId].abilities,
      npcDatabase[BossSpawner.currentBossId]._imagePath
    );
  }
}
