import { inject, injectable, interfaces } from 'inversify';
// tslint:disable-next-line:no-import-side-effect
import 'reflect-metadata';
import { AbilitySound, AbilityType, ConstantCombatMessages } from './common';
import { TYPES } from './config/type';
import { IAbility, ICharacter, IPlayer } from './contracts/models';
import { IDomManipulator } from './contracts/models/DOMManipulation/IDomManipulator';

@injectable()
// tslint:disable-next-line:no-unnecessary-class
export class AbilityHandler {
  public static dom: IDomManipulator;
  private static myContainer: interfaces.Container;

  public static INIT(myContainer: interfaces.Container): void {
    this.myContainer = myContainer;
    this.dom = myContainer.get<IDomManipulator>(TYPES.IDomManipulator);
  }

  public static HANDLEABILITY(
    ability: IAbility,
    activator: ICharacter,
    opponent: ICharacter
  ): void {

    switch (ability.abilityType) {
      case AbilityType.Attack:
        this.ACTIVATEATTACKABILITY(ability, activator, opponent);
        break;
      case AbilityType.Fire:
        this.ACTIVATEFIREABILITY(ability, activator, opponent);
        break;
      case AbilityType.Frost:
        this.ACTIVATEFROSTABILITY(ability, activator, opponent);
        break;
      case AbilityType.Nature:
        this.ACTIVATENATUREABILITY(ability, activator, opponent);
        break;
      case AbilityType.Healing:
        this.ACTIVATEHEALINGABILITY(ability, activator);
        break;
      case AbilityType.Mana:
        this.ACTIVATEMANAABILITY(ability, activator);
        break;
      default:
        throw new Error('Unknown ability type');
    }

    this.reduceMana(activator, ability);
    this.dom.playSound(`${AbilitySound[ability.abilityType]}`);
  }

  // tslint:disable-next-line:function-name
  public static ACTIVATEATTACKABILITY(
    ability: IAbility,
    activator: ICharacter,
    opponent: ICharacter
  ): void {
    opponent.hp -= ability.effectPoints;

    const msg: string = ConstantCombatMessages.getMsg(
      ability,
      activator,
      ability.effectPoints,
      opponent
    );
    this.dom.logToConsole(`${msg}`, `${AbilityType[ability.abilityType]}`);
  }

  public static ACTIVATEFROSTABILITY(
    ability: IAbility,
    activator: ICharacter,
    opponent: ICharacter
  ): void {
    let damageToDeal: number =
      ability.effectPoints +
      activator.elementsStats.frostPoints -
      opponent.elementsStats.frostPoints;

    if (damageToDeal < 0) {
      damageToDeal = 0;
    }
    opponent.hp -= damageToDeal;

    const msg: string = ConstantCombatMessages.getMsg(
      ability,
      activator,
      damageToDeal,
      opponent
    );
    this.dom.logToConsole(`${msg}`, `${AbilityType[ability.abilityType]}`);
  }
  public static ACTIVATEFIREABILITY(
    ability: IAbility,
    activator: ICharacter,
    opponent: ICharacter
  ): void {
    let damageToDeal: number =
      ability.effectPoints +
      activator.elementsStats.firePoints -
      opponent.elementsStats.firePoints;

    if (damageToDeal < 0) {
      damageToDeal = 0;
    }
    opponent.hp -= damageToDeal;

    const msg: string = ConstantCombatMessages.getMsg(
      ability,
      activator,
      damageToDeal,
      opponent
    );
    this.dom.logToConsole(`${msg}`, `${AbilityType[ability.abilityType]}`);
  }

  public static ACTIVATENATUREABILITY(
    ability: IAbility,
    activator: ICharacter,
    opponent: ICharacter
  ): void {
    let damageToDeal: number =
      ability.effectPoints +
      activator.elementsStats.naturePoints -
      opponent.elementsStats.naturePoints;

    if (damageToDeal < 0) {
      damageToDeal = 0;
    }
    opponent.hp -= damageToDeal;

    const msg: string = ConstantCombatMessages.getMsg(
      ability,
      activator,
      damageToDeal,
      opponent
    );
    this.dom.logToConsole(`${msg}`, `${AbilityType[ability.abilityType]}`);
  }

  public static ACTIVATEHEALINGABILITY(
    ability: IAbility,
    activator: ICharacter
  ): void {
    const healing: number = (ability.effectPoints / 100) * activator.maxHP;
    activator.hp += healing;

    const msg: string = ConstantCombatMessages.getMsg(
      ability,
      activator,
      healing
    );
    this.dom.logToConsole(`${msg}`, `${AbilityType[ability.abilityType]}`);
  }

  public static ACTIVATEMANAABILITY(
    ability: IAbility,
    activator: ICharacter
  ): void {
    const manaRestored: number =
      (ability.effectPoints / 100) * (<IPlayer>activator).maxHP;
    (<IPlayer>activator).mana += manaRestored;

    const msg: string = ConstantCombatMessages.getMsg(
      ability,
      activator,
      manaRestored
    );
    this.dom.logToConsole(`${msg}`, `${AbilityType[ability.abilityType]}`);
  }

  private static reduceMana(activator: ICharacter, ability: IAbility): void {
    if (ability.manaCost > 0) {
      (<IPlayer>activator).mana -= ability.manaCost;
    }
  }
}
