import { ItemType } from './../../common/item-types';
import { IItem } from './../../contracts/models/items/item';
// tslint:disable-next-line:no-unnecessary-class
export class ItemIconsActivator {
  public static ITEMICONSACTIVATOR(items: IItem[]): void {
    items.forEach((item: IItem) => {

      if (item.level !== 0) {
        document
          .getElementById(`${ItemType[item.itemType].toLowerCase()}Item`)
          .classList.remove('inactive');

        let onHover: string = '';
        onHover += (item.bonusHp > 0) ? `Bonus HP: ${item.bonusHp}\n` : '';
        onHover += (item.bonusMana > 0) ? `Bonus Mana: ${item.bonusMana}\n` : '';
        onHover += (item.elementsStats.firePoints > 0) ? `Fire points: ${item.elementsStats.firePoints}\n` : '';
        onHover += (item.elementsStats.frostPoints > 0) ? `Frost points: ${item.elementsStats.frostPoints}\n` : '';
        onHover += (item.elementsStats.naturePoints > 0) ? `Nature points: ${item.elementsStats.naturePoints}\n` : '';

        document
          .getElementById(`${ItemType[item.itemType].toLowerCase()}Item`)
          .setAttribute(
            'title',
            `${ItemType[item.itemType]}\n${onHover}`
          );
      }
    });
  }
}
