import { AbilityType } from '../../common';
import { IAbility, ICharacter, IPlayer } from '../../contracts/models';
import { IDomManipulator } from '../../contracts/models/DOMManipulation/IDomManipulator';
import { container } from './../../config/ioc.config';
import { TYPES } from './../../config/type';
import { AbilityIconsActivator } from './abilityIconsActivator';

// tslint:disable-next-line:no-unnecessary-class
export class AbilityListener {
  public static ADDEVENTSTOABILITIES(
    abilities: IAbility[],
    player: IPlayer,
    opponent: ICharacter
  ): void {
    const dom: IDomManipulator = container.get(TYPES.IDomManipulator);
    // tslint:disable-next-line:typedef
    abilities.forEach((ability: IAbility) => {
      const id: string = `${AbilityType[
        ability.abilityType
      ].toLowerCase()}Ability`;

      AbilityListener.removeListenersFrom(id);
      document.getElementById(id).addEventListener('click', () => {
        ability.activate(player, opponent);
        AbilityIconsActivator.deactivator();
      });
    });
  }

  // tslint:disable-next-line:function-name
  public static removeListenersFrom(htmlID: string): void {
    const old: HTMLElement = document.getElementById(htmlID);
    const newElement: Node = old.cloneNode(true);
    old.parentNode.replaceChild(newElement, old);
  }
}
