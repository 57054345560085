import { injectable } from 'inversify';
// tslint:disable-next-line:no-import-side-effect
import 'reflect-metadata';
import { ILocalStorageDatabaseManipulator } from './../contracts/data/localStorageDatabaseManipulator';
import { IAbility } from './../contracts/models/abilities/ability';
import { IPlayer } from './../contracts/models/characters/player';
import { IInventory } from './../contracts/models/inventory';
import { IItem } from './../contracts/models/items/item';
import { Ability } from './../models/abilities/ability-model';
import { Player } from './../models/characters/player-model';
import { ElementsStats } from './../models/elements-stats-model';
import { Inventory } from './../models/inventory-model';
import { Item } from './../models/items/item-model';
@injectable()
export class LocalStorageDatabaseManipulator
  implements ILocalStorageDatabaseManipulator {
  public getSavedPlayerData(): IPlayer | null {
    return this.buildPlayerObject();
  }
  public savePlayerData(player: IPlayer): void {
    localStorage.setItem('player', JSON.stringify(player));
  }

  public savePlayersPassedLevels(level: number): void {
    localStorage.setItem('passedLevels', JSON.stringify(level));
  }

  public getPlayersPassedLevels(): number {
    return JSON.parse(localStorage.getItem('passedLevels'));
  }
  public savePlayerInventory(inventory: IItem[]): void {
    localStorage.setItem('inventory', JSON.stringify(inventory));
  }

  public getPlayerInventory(): IInventory {
    const inventory: IInventory = new Inventory();
    JSON.parse(localStorage.getItem('inventory')).forEach((element: Object) => {
      const item: IItem = new Item(
        Object.values(element)[0],
        Object.values(element)[1],
        new ElementsStats(
          Number(Object.values(Object.values(element)[2])[0]),
          Number(Object.values(Object.values(element)[2])[1]),
          Number(Object.values(Object.values(element)[2])[2])
        ),
        Object.values(element)[3],
        Object.values(element)[4],
        Object.values(element)[5],
        Object.values(element)[6]);

      for (let i: number = 0; i < +Object.values(element)[2]; i += 1) {
        item.upgrade();
      }
      inventory.addItem(item);
    });

    return inventory;
  }

  private buildPlayerObject(): IPlayer | null {
    if (localStorage.getItem('player')) {

      const savedData: IPlayer[] = Array.from(
        Object.values(JSON.parse(localStorage.getItem('player')))
      );
      const elementStats: number[] = Array.from(Object.values(savedData[3]));
      const abilities: Object[] = Array.from(Object.values(savedData[4]));
      const abilitiesArray: IAbility[] = [];

      abilities.forEach((ability: Object) => {
        const newAbility: number[] = Array.from(Object.values(ability));
        abilitiesArray.push(
          new Ability(newAbility[0], newAbility[1], newAbility[2])
        );
      });

      const player: IPlayer = new Player(
        savedData[0].toString(),
        Number(savedData[1]),
        Number(savedData[2]),
        new ElementsStats(elementStats[0], elementStats[1], elementStats[2]),
        abilitiesArray,
        Number(savedData[7]),
        Number(savedData[6])
      );

      this.getPlayerInventory().items.forEach((item: IItem) => {
        player.inventory.addItem(item);
      });

      return player;
    }

    return null;
  }
}
