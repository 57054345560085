import { inject, injectable } from 'inversify';
// tslint:disable-next-line:no-import-side-effect
import 'reflect-metadata';
import { IInventory } from '../../contracts/models';
import { IStats } from '../../contracts/models/stats';
import { container } from './../../config/ioc.config';
import { TYPES } from './../../config/type';
import { ILocalStorageDatabaseManipulator } from './../../contracts/data/localStorageDatabaseManipulator';
import { IPlayerStatsUpdater } from './../../contracts/dom-manipulator/player-stats-updater';
import { IPlayer } from './../../contracts/models/characters/player';
import { IItem } from './../../contracts/models/items/item';
@injectable()
export class PlayerStatsUpdater implements IPlayerStatsUpdater {
  private _dbManipulation: ILocalStorageDatabaseManipulator;
  public constructor(
    @inject(TYPES.IDomManipulator) db: ILocalStorageDatabaseManipulator
  ) {
    this._dbManipulation = db;
  }

  public playerStatsUpdater(player: IPlayer): void {
    const newStats: IStats = player.inventory.getStats();
    document
      .getElementById('attackDescription')
      .setAttribute('value', `0`);

    document
      .getElementById('fireAttackDescription')
      .setAttribute('value', `${newStats.elementsStats.firePoints}`);
    document
      .getElementById('frostAttackDescription')
      .setAttribute('value', `${newStats.elementsStats.frostPoints}`);
    document
      .getElementById('natureAttackDescription')
      .setAttribute('value', `${newStats.elementsStats.naturePoints}`);
    document
      .getElementById('healthRefillDescription')
      .setAttribute('value', `${newStats.bonusHp}`);
    document
      .getElementById('manaRefillDescription')
      .setAttribute('value', `${newStats.bonusMana}`);
  }
}
