import { IAbility, IElementsStats } from '../../contracts/models';
import { ICharacter } from '../../contracts/models/characters';

export abstract class Character implements ICharacter {
  private _name: string;
  private _level: number;
  private _hp: number;
  private _maxHP: number;
  private _elementsStats: IElementsStats;
  private _abilities: IAbility[];

  public constructor(
    name: string,
    level: number,
    maxHP: number,
    stats: IElementsStats,
    abilities: IAbility[]
  ) {
    this.name = name;
    this.level = level;
    this.maxHP = maxHP;
    this.elementsStats = stats;
    this._abilities = abilities;
    this.hp = maxHP;
  }

  public set name(name: string) {
    this._name = name;
  }

  public get name(): string {
    return this._name;
  }

  public set level(level: number) {
    this._level = level;
  }

  public get level(): number {
    return this._level;
  }

  public set hp(hp: number) {
    if (hp < 0) {
      hp = 0;
    } else if (hp > this.maxHP) {
      hp = this.maxHP;
    }

    this._hp = hp;
  }

  public get hp(): number {
    return this._hp;
  }

  public set maxHP(maxHP: number) {
    this._maxHP = maxHP;
  }

  public get maxHP(): number {
    return this._maxHP;
  }

  public get elementsStats(): IElementsStats {
    return this._elementsStats;
  }

  public set elementsStats(stats: IElementsStats) {
    this._elementsStats = stats;
  }

  public get abilities(): IAbility[] {
    return this._abilities;
  }
  public getAbilities(): IAbility[] {
    return this._abilities;
  }
}
