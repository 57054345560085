import { injectable } from 'inversify';
// tslint:disable-next-line:no-import-side-effect
import 'reflect-metadata';
import { IInventory, IItem } from '../contracts/models';
import { IStats } from '../contracts/models/stats';
import { ElementsStats } from './elements-stats-model';
@injectable()
export class Inventory implements IInventory {
  private _maxSlots: number;
  private _items: IItem[];

  public constructor(maxSlots: number = 12) {
    this._items = [];
    this.maxSlots = maxSlots;
  }

  public get maxSlots(): number {
    return this._maxSlots;
  }

  public set maxSlots(maxSlots: number) {
    if (maxSlots < 12) {
      throw new Error('Inventory`s MaxSlots must be at least 12');
    }
    this._maxSlots = maxSlots;
  }

  public get items(): IItem[] {
    return this._items.slice();
  }

  public addItem(item: IItem): IItem {
    if (this._items.length + 1 > this.maxSlots) {
      throw new Error('Maximum slots capacity exceeded');
    }
    this._items.push(item);

    return item;
  }

  public getStats(): IStats {
    return this.items.reduce(
      (stat: IStats, item: IItem) => {
        stat.bonusHp += item.bonusHp;
        stat.bonusMana += item.bonusMana;
        stat.elementsStats.naturePoints += item.elementsStats.naturePoints;
        stat.elementsStats.firePoints += item.elementsStats.firePoints;
        stat.elementsStats.frostPoints += item.elementsStats.frostPoints;

        return stat;
      },
      {
        bonusHp: 0,
        bonusMana: 0,
        elementsStats: new ElementsStats(0, 0, 0)
      }
    );
  }
}
