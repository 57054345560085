import { ITypes } from './types-interface';

export const TYPES: ITypes = {
  IEngine: Symbol.for('IEngine'),
  IDomManipulator: Symbol.for('IDomManipulator'),
  ILocalStorageDatabaseManipulator: Symbol.for(
    'ILocalStorageDatabaseManipulator'
  ),
  IPlayerStatsUpdater: Symbol.for('IPlayerStatsUpdater'),
  IBossSpawner: Symbol.for('IBossSpawner'),
  ICombatOutcome: Symbol.for('ICombatOutcome'),
  IInventory: Symbol.for('IInventory'),
  ISetAbilityTitles: Symbol.for('ISetAbilityTitles'),
  INPCAbilityManager: Symbol.for('INPCAbilityManager'),
  ICombatManager: Symbol.for('ICombatManager')
};
