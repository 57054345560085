import { AbilityHandler } from '../../ability-handler';
import { AbilityType } from '../../common/ability-types';
import { ICharacter } from '../../contracts/models';
import { IAbility } from '../../contracts/models/abilities/ability';
export class Ability implements IAbility {
  private _abilityType: AbilityType;
  private _manaCost: number;
  private _effectPoints: number;

  public constructor(
    abilityType: AbilityType,
    manaCost: number,
    effectPoints: number
  ) {
    this.abilityType = abilityType;
    this.manaCost = manaCost;
    this._effectPoints = effectPoints;
  }

  public set abilityType(abilityType: AbilityType) {
    this._abilityType = abilityType;
  }

  public get abilityType(): AbilityType {
    return this._abilityType;
  }

  public set manaCost(manaCost: number) {
    this._manaCost = manaCost;
  }

  public get manaCost(): number {
    return this._manaCost;
  }

  public set effectPoints(effectPoints: number) {
    this._effectPoints = effectPoints;
  }

  public get effectPoints(): number {
    return this._effectPoints;
  }

  public activate(activator: ICharacter, opponent: ICharacter): void {
    AbilityHandler.HANDLEABILITY(this, activator, opponent);
  }
}
